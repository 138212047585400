import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Video from './Video';

const VideosBlock = ({ data }) => {
  const videos = data;
  return (
    <section
      className="panel two"
      data-aos="fade-up"
      data-aos-offset="0"
      data-aos-delay="50"
    >
      <header>
        <h1>Videos</h1>
      </header>
      <div className="panel-inner">
        {!videos.length && (
          <span className="none-msg">
            No videos.
          </span>
        )}
        {videos.length > 0 && (
          <div>
            {videos.map(({ node }) => (
              <article key={node.prismicId}>
                <Video video={node.data.video} />
              </article>
            ))}
          </div>
        )}
      </div>
      <footer>
        <small className="label">
          <Link to="/videos">More videos</Link>
        </small>
      </footer>
    </section>
  );
};

VideosBlock.propTypes = {
  data: PropTypes.array,
};

export default VideosBlock;
