import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Seo from '../components/Seo';
import EventsBlock from '../components/EventsBlock';
import VideosBlock from '../components/VideosBlock';
import ArticlesBlock from '../components/ArticlesBlock';

const IndexPage = ({ data }) => (
  <div>
    <div className="row">
      <div
        className="large-4 medium-6 small-12 columns"
      >
        <EventsBlock data={data.allPrismicEvent.edges} />
        <section
          className="callout panel"
          data-aos="fade-up"
          data-aos-offset="0"
          data-aos-delay="0"
        >
          <header>
            <h1>
              <Link to="/messages">Fan Board</Link>
            </h1>
          </header>
          <footer>
            <Link to="/messages">Leave a message</Link>
          </footer>
        </section>
      </div>
      <div
        className="large-4 medium-6 small-12 columns"
      >
        <VideosBlock data={data.allPrismicVideo.edges} />
      </div>
      <div
        className="large-4 medium-6 small-12 columns"
      >
        <ArticlesBlock data={data.allPrismicArticle.edges} />
      </div>
    </div>
    <Seo />
  </div>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    allPrismicArticle: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allPrismicEvent: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allPrismicVideo: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default IndexPage;

export const query = graphql`
  {
    allPrismicEvent(
      limit: 4,
      sort: {
        fields: [
          data___date_beginning
        ],
        order: ASC
      },
      filter: {
        upcoming: {
          eq : true
        }
      }
    ) {
      edges {
        node {
          prismicId
          slugs
          data {
            city
            state
            country
            venue
            date_beginning(
              formatString: "MMM D 'YY"
            )
            description {
              html
            }
          }
        }
      }
    }
    allPrismicVideo(
      limit: 3,
      sort: {
        fields: [data___date],
        order: DESC
      }
    ) {
      edges {
        node {
          prismicId
          data {
            date(
              formatString: "MMMM D, YYYY"
            )
            description {
              html
              text
            }
            title {
              html
              text
            }
            video {
              thumbnail_url
              provider_name
              embed_url

            }
          }
        }
      }
    }
    allPrismicArticle(
      limit: 3,
      sort: {
        fields: [data___date],
        order: DESC
      }
    ) {
      edges {
        node {
          prismicId
          slugs
          data {
            title
            date(
              formatString: "MMMM D, YYYY"
            )
          }
        }
      }
    }
  }
`;
