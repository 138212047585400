import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const ArticlesBlock = ({ data }) => {
  const articles = data;
  return (
    <section
      className="panel three"
      data-aos="fade-up"
      data-aos-offset="0"
      data-aos-delay="150"
    >
      <header>
        <h1>News</h1>
      </header>
      <div className="panel-inner">
        {!articles.length && (
          <span className="none-msg">
            No articles.
          </span>
        )}
        {articles.length > 0 && (
          <div>
            {articles.map(({ node }) => (
              <article key={node.prismicId}>
                <header>
                  <h2>
                    <Link
                      to={`/content/news/${node.prismicId}/${node.slugs[0]}`}
                    >
                      {node.data.title}
                    </Link>
                  </h2>
                  <small>
                    <time>
                      {node.data.date}
                    </time>
                  </small>
                </header>
              </article>
            ))}
          </div>
        )}
      </div>
      <footer>
        <small className="label">
          <Link to="/news">More news & updates</Link>
        </small>
      </footer>
    </section>
  )
};

ArticlesBlock.propTypes = {
  data: PropTypes.array,
};

export default ArticlesBlock;
