import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const EventsBlock = ({ data }) => {
  const events = data;
  return (
    <section
      className="panel one"
      data-aos="fade-up"
      data-aos-offset="0"
      data-aos-delay="0"
    >
      <header>
        <h1>Shows</h1>
      </header>
      <div className="panel-inner">
        {!events.length && (
          <span className="none-msg">
            No upcoming concerts. Stay tuned!
          </span>
        )}
        {events.length > 0 && (
          <div>
            {events.map(({ node }) => (
              <article className="concert concert-home" key={node.prismicId}>
                <div className="row">
                  <div className="columns col-1 small-12">
                    <header>
                      <small className="ish">
                        {node.data.country}
                      </small>
                      <h2>
                        <time
                          className="bumper success"
                        >
                          {node.data.date_beginning}
                        </time>
                        {node.data.city}
                      </h2>
                    </header>
                  </div>
                  <div className="columns col-2 small-12">
                    <span className="venue">
                      <Link to={`/content/concert-dates/${node.prismicId}/${node.slugs[0]}`}>{node.data.venue}</Link>
                    </span>
                  </div>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
      <footer>
        <small className="label">
          <Link to="/concert-dates">All concerts</Link>
        </small>
      </footer>
    </section>
  );
};

EventsBlock.propTypes = {
  data: PropTypes.array,
};

export default EventsBlock;
